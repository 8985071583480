import {
  DashboardOutlined,
  DollarOutlined,
  FileImageOutlined,
  GlobalOutlined,
  HomeOutlined,
  LineChartOutlined,
  SearchOutlined,
  SendOutlined,
  SwitcherOutlined,
  ToolOutlined,
  ProfileOutlined,
  CloseCircleOutlined,
  GoldOutlined,
  CreditCardOutlined,
  TableOutlined,
  ApiOutlined,
  ClockCircleOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}`,
    title: "home",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "analytics",
    path: `${APP_PREFIX_PATH}/analytics`,
    title: "analytics",
    icon: DollarOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "ccu",
        path: `${APP_PREFIX_PATH}/analytics/ccu`,
        title: "sidenav.analytics.ccu",
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "revenue",
        path: `${APP_PREFIX_PATH}/analytics/revenue`,
        title: "sidenav.analytics.revenue",
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "new-players-revenue",
        path: `${APP_PREFIX_PATH}/analytics/new-players-revenue`,
        title: "sidenav.analytics.new-players-revenue",
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "gateway-info",
        path: `${APP_PREFIX_PATH}/analytics/gateway-info`,
        title: "sidenav.analytics.gateway-info",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "crm",
        path: `${APP_PREFIX_PATH}/analytics/crm`,
        title: "sidenav.analytics.crm",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "item-amount",
        path: `${APP_PREFIX_PATH}/analytics/item-amount`,
        title: "sidenav.analytics.item-amount",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "performance",
        path: `${APP_PREFIX_PATH}/analytics/performance`,
        title: "sidenav.analytics.performance",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "urlshorter-metrics",
        path: `${APP_PREFIX_PATH}/analytics/urlshorter-metrics`,
        title: "sidenav.analytics.urlshorter-metrics",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "royalarena-reports",
        path: `${APP_PREFIX_PATH}/analytics/royalarena-reports`,
        title: "sidenav.analytics.royalarena-reports",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "activeplayers-per-country",
        path: `${APP_PREFIX_PATH}/analytics/activeplayers-per-country`,
        title: "sidenav.analytics.activeplayers-per-country",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "operation",
    path: `${APP_PREFIX_PATH}/operation`,
    title: "operation",
    icon: FileImageOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "upload-banner",
        path: `${APP_PREFIX_PATH}/operation/upload-banner`,
        title: "sidenav.operation.upload-banner",
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "manage-patch",
        path: `${APP_PREFIX_PATH}/operation/manage-patch`,
        title: "sidenav.operation.manage-patch",
        icon: SwitcherOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "schedule-downtime",
        path: `${APP_PREFIX_PATH}/operation/schedule-downtime`,
        title: "sidenav.operation.schedule-downtime",
        icon: ClockCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "clone-accounts",
        path: `${APP_PREFIX_PATH}/operation/clone-accounts`,
        title: "sidenav.operation.clone-accounts",
        icon: DeliveredProcedureOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "support",
    path: `${APP_PREFIX_PATH}/support`,
    title: "support",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "find-player",
        path: `${APP_PREFIX_PATH}/support/find-player`,
        title: "sidenav.support.findplayer",
        icon: SearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "find-guild",
        path: `${APP_PREFIX_PATH}/support/find-guild`,
        title: "sidenav.support.findguild",
        icon: SearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "rank-players",
        path: `${APP_PREFIX_PATH}/support/rank-players`,
        title: "sidenav.support.rankPlayers",
        icon: TableOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "pr-ranking",
        path: `${APP_PREFIX_PATH}/support/pr-ranking`,
        title: "sidenav.operation.pr-ranking",
        icon: TableOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "twitch-users",
        path: `${APP_PREFIX_PATH}/support/twitch-users`,
        title: "sidenav.operation.twitch-users",
        icon: SearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "send-items",
        path: `${APP_PREFIX_PATH}/support/send-items`,
        title: "sidenav.support.sendItems",
        icon: SendOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "ban-players",
        path: `${APP_PREFIX_PATH}/support/ban-players`,
        title: "sidenav.support.banPlayers",
        icon: CloseCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "gm-tools",
        path: `${APP_PREFIX_PATH}/support/gm-tools`,
        title: "sidenav.support.gmTools",
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "gold-count",
        path: `${APP_PREFIX_PATH}/support/gold-count`,
        title: "sidenav.support.goldCount",
        icon: GoldOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "accounts-np",
        path: `${APP_PREFIX_PATH}/support/accounts-np`,
        title: "sidenav.support.accountsNp",
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "global-bot",
        path: `${APP_PREFIX_PATH}/support/global-bot`,
        title: "sidenav.support.globalBot",
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "global-bot-tool",
      //   path: `${APP_PREFIX_PATH}/support/global-bot-tool`,
      //   title: "sidenav.support.globalBotTool",
      //   icon: ToolOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "editor-info-json",
        path: `${APP_PREFIX_PATH}/support/editor-info-json`,
        title: "sidebar.support.editorInfoJson",
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "paid-traffic-emails",
        path: `${APP_PREFIX_PATH}/support/paid-traffic-emails`,
        title: "sidebar.support.paidTrafficEmails",
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "all-emails",
        path: `${APP_PREFIX_PATH}/support/all-emails`,
        title: "sidebar.support.allEmails",
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "action-logs",
        path: `${APP_PREFIX_PATH}/support/action-logs`,
        title: "sidenav.support.actionLogs",
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "log-analyzer",
        path: `${APP_PREFIX_PATH}/support/log-analyzer`,
        title: "sidenav.support.logAnalyzer",
        icon: SearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "developer",
    path: `${APP_PREFIX_PATH}/developer`,
    title: "development",
    icon: FileImageOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "manage-rds",
        path: `${APP_PREFIX_PATH}/developer/manage-rds`,
        title: "sidenav.developer.manageRds",
        icon: ApiOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
